// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { withoutFelaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ checked, theme }) => {
  const ease = theme.ToggleButton.timingFunction
  const horizontalPadding = '10px'
  const visibleLabelProps = {
    boxSizing: 'border-box',
    marginTop: '-1px',
    position: 'absolute',
    textAlign: 'center',
    transition: `transform 300ms ${ease}`,
    width: '100%',
    padding: '5px 0',
  }

  const onLabel = {
    ...visibleLabelProps,
    backgroundColor: theme.ToggleButton.checked.base,
    className: 'ToggleButton__onLabel',
    color: theme.ToggleButton.checked.text,
    left: 0,
    paddingLeft: horizontalPadding,
    paddingRight: '28px',
    transform: `translateX(${checked ? '0%' : 'calc(-100% - 8px)'})`,
  }

  const offLabel = {
    ...visibleLabelProps,
    backgroundColor: theme.ToggleButton.default.base,
    className: 'ToggleButton__offLabel',
    color: theme.ToggleButton.default.text,
    paddingLeft: '28px',
    paddingRight: horizontalPadding,
    right: 0,
    transform: `translateX(${checked ? '100%' : '0%'})`,
  }

  return {
    input: {
      className: 'ToggleButton__input',
      left: '-9999vw',
      position: 'absolute',
      ':disabled + label': {
        opacity: 0.5,
      },
      ':focus + label': {
        borderColor: theme.Input.focus.accent,
        boxShadow: `0 0 4px 0 ${theme.Input.focus.accent}`,
      },
    },
    label: {
      borderColor: checked
        ? theme.ToggleButton.checked.base
        : theme.ToggleButton.default.text,
      borderStyle: 'solid',
      borderWidth: '2px',
      borderRadius: '15px',
      boxSizing: 'border-box',
      className: classNames('ToggleButton__label', {
        '--checked': checked,
      }).replace(/\s/g, ''),
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: '14px',
      height: '28px',
      overflow: 'hidden',
      position: 'relative',
      width: 'auto',
      ':after': {
        backgroundColor: theme.ToggleButton.checked.text,
        border: 'inherit',
        boxSizing: 'border-box',
        borderRadius: '50%',
        content: '""',
        display: 'block',
        height: '28px',
        left: checked ? '100%' : '-2px',
        pointerEvents: 'none',
        position: 'absolute',
        right: 'auto',
        top: '-2px',
        transition: `all 300ms ${ease}`,
        transform: `translateX(${checked ? 'calc(-100% + 2px)' : '0%'})`,
        width: '28px',
      },
    },
    onLabelHidden: {
      ...onLabel,
      className: 'ToggleButton__onLabelHidden',
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
      position: 'static',
      transform: 'translate(0, 0)',
      visibility: 'hidden',
      width: 'auto',
    },

    offLabelHidden: {
      ...offLabel,
      className: 'ToggleButton__offLabelHidden',
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
      position: 'static',
      transform: 'translate(0, 0)',
      visibility: 'hidden',
      width: 'auto',
    },

    onLabel,
    offLabel,
  }
}

export type ToggleButtonProps = FelaPropsType & {
  checked?: boolean,
  id: string,
  offLabel?: string,
  onLabel?: string,
}

const ToggleButton = (props: ToggleButtonProps) => {
  const { checked, id, offLabel, onLabel, styles, ...restProps } = props

  const { t: translation } = useTranslation()
  const defaultOffLabel = translation('off')
  const defaultOnLabel = translation('on')
  return (
    <React.Fragment>
      <input
        {...withoutFelaProps(restProps)}
        checked={checked}
        className={styles.input}
        id={id}
        type="checkbox"
      />
      <label htmlFor={id} className={styles.label}>
        <span className={styles.onLabelHidden}>
          {onLabel || defaultOnLabel}
        </span>
        <span className={styles.offLabelHidden}>
          {offLabel || defaultOffLabel}
        </span>
        <span className={styles.onLabel}>
          {(checked && onLabel) || defaultOnLabel}
        </span>
        <span className={styles.offLabel}>
          {checked || offLabel || defaultOffLabel}
        </span>
      </label>
    </React.Fragment>
  )
}

export default connect(styleRules)(ToggleButton)
