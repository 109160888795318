// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useRelayEnvironment } from 'react-relay'
import { upperCase } from 'lodash'

import Tick from 'react-ui/assets/icons/tick.svg'
import { Column, Row } from 'react-ui/components/Grid'
import Icon from 'react-ui/components/Icon'
import { commit as requestSupportPersonFeedback } from 'mutations/RequestSupportPersonFeedback'
import { commit as removeSupportPerson } from 'mutations/SupportPersonAssignment/Remove'
import { formatDistance } from 'shared/utils/DateFormatUtils'
import FlexContainer from 'care-ui/layouts/Flex/FlexContainer'
import Button from 'care-ui/molecules/Button/Button'
import Divider from 'care-ui/molecules/Divider/Divider'
import Text from 'care-ui/molecules/Text/Text'

import { type modifiedSupportPersonAssignmentType } from '../SupportPersonModal'

import { styleRules } from './ViewSupportPersonInfo.style'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = {
  supportPersonAssignment: modifiedSupportPersonAssignmentType,
} & FelaPropsType

const ViewSupportPersonInfo = (props: PropsType) => {
  const {
    rules,
    styles,
    supportPersonAssignment: {
      accepted_invitation,
      id,
      last_completed,
      last_requested_at,
      nickname,
      relationship,
      support_person: { email },
      justMade,
      justRemoved,
      justActioned,
    },
  } = props

  const environment = useRelayEnvironment()

  function reinviteSupportPersonOrGetSupport() {
    requestSupportPersonFeedback({
      environment,
      variables: {
        input: {
          support_person_feedback: {
            id,
          },
        },
      },
    })
  }

  function removeSupportPersonForAnIndividual() {
    removeSupportPerson({
      environment,
      variables: {
        input: {
          id,
        },
      },
    })
  }

  const { t: translation } = useTranslation()

  const actionedMessage = (
    <p
      data-component-id="added_support_person_notice"
      className={styles.invited}
    >
      <strong>
        {accepted_invitation
          ? translation('support_request')
          : upperCase(translation('email'))}{' '}
        {translation('sent_to')}
      </strong>
      &nbsp;
      {nickname.toUpperCase()} <Icon extend={rules.invitedIcon} as={Tick} />
    </p>
  )

  const invitedMessage = (
    <p
      data-component-id="added_support_person_notice"
      className={styles.invited}
    >
      <strong>{translation('invite_sent_to')}</strong> {nickname.toUpperCase()}
      &nbsp;
      <Icon extend={rules.invitedIcon} as={Tick} />
    </p>
  )

  const removedMessage = (
    <>
      <p className={styles.removed}>
        <strong>
          {nickname.toUpperCase()}: {translation('has_been_removed')}
        </strong>
      </p>

      <p>{translation('they_will_not_be_notified_of_being_removed')}</p>
    </>
  )

  return (
    <div>
      <Divider />

      {justRemoved ? (
        removedMessage
      ) : (
        <>
          {justMade && invitedMessage}
          {justActioned && actionedMessage}

          <FlexContainer direction="column" gap="md">
            <Row>
              <Column xs={12} md={4}>
                <Text as="h5" bold>
                  {translation('name')}
                </Text>

                <Text as="p">{nickname}</Text>
              </Column>

              <Column xs={12} md={4}>
                <Text as="h5" bold>
                  {translation('relationship')}
                </Text>

                <Text as="p">{relationship}</Text>
              </Column>

              <Column xs={12} md={4}>
                <Text as="h5" bold>
                  {translation('email')}
                </Text>

                <Text as="p">{email}</Text>
              </Column>
            </Row>

            <Row>
              <Column xs={12} md={4} className={styles.textColumn}>
                <Text as="h5" bold>
                  {translation('history_of_requests')}
                </Text>

                <Text as="p" size="sm">
                  {translation('last_requested')}&nbsp;
                  {formatDistance()(new Date())(
                    new Date(last_requested_at),
                  )}{' '}
                  {translation('ago')}
                </Text>

                {last_completed && (
                  <Text as="p" size="sm">
                    {translation('last_completed')}&nbsp;
                    {formatDistance()(new Date())(
                      new Date(last_completed),
                    )}{' '}
                    {translation('ago')}
                  </Text>
                )}
              </Column>

              <Column xs={12} md={4} extend={rules.buttonColumn}>
                <Button
                  dataTestId="removeButton"
                  variant="danger"
                  color="danger"
                  onClick={removeSupportPersonForAnIndividual}
                >
                  {translation('remove')}
                </Button>
              </Column>

              <Column xs={12} md={4} extend={rules.buttonColumn}>
                <Button
                  dataTestId="inputButton"
                  variant="secondary"
                  onClick={reinviteSupportPersonOrGetSupport}
                >
                  {accepted_invitation
                    ? translation('get_support')
                    : translation('reinvite_support')}
                </Button>
              </Column>
            </Row>
          </FlexContainer>
        </>
      )}
    </div>
  )
}

export default connect(styleRules)(ViewSupportPersonInfo)
