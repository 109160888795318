// @flow

import type { TFunction } from 'react-i18next'

import { type IndividualsTable_individual } from 'platform_web/pages/Staff/Individuals/query/__generated__/IndividualsTable_individual.graphql'
import { shortDateFormat } from 'platform_web/services/dateTime'

const formatDate = (date) => (date ? `(${shortDateFormat(date)})` : '')

const getRoleStatus = (
  individual: IndividualsTable_individual,
  translation: TFunction,
) => {
  const {
    individual_detail,
    state,
    removed_at,
    user: {
      invitation_created_at,
      invitation_expired,
      invitation_requested_at,
      invitation_accepted,
      invitation_accepted_at,
    },
  } = individual

  if (state === 'discharged') {
    return `${translation(state)} ${formatDate(
      individual_detail?.discharged_at,
    )}`
  }

  if (state === 'invited') {
    if (
      invitation_requested_at &&
      invitation_requested_at > invitation_created_at
    ) {
      return `${translation('reinvite_requested')} ${formatDate(
        invitation_requested_at,
      )}`
    }
    if (invitation_accepted) {
      return `${translation('accepted')} ${formatDate(invitation_accepted_at)}`
    }
    const status = invitation_expired
      ? translation('expired')
      : translation(state)
    return `${status} ${formatDate(invitation_created_at)}`
  }

  if (state === 'removed') {
    return `${translation(state)} ${formatDate(removed_at)}`
  }

  return translation(state)
}

export default getRoleStatus
