// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { capitalize, omit } from 'lodash'

import DataGridRowFooterRenderer from 'components/DataGridRowFooter/DataGridRowFooterRenderer'
import DataGrid, { TextCell } from 'react-ui/components/DataGrid'
import {
  staffCliniciansColumn,
  staffInvitedIndividualColumnConfig,
} from 'react-ui/components/Tables/columns'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import getRoleStatus from 'platform_web/utility/getRoleStatus'

import IndividualsTableStandardActions from '../../IndividualsTableStandardActions'
import StaffIndividualsTableFooter from '../StaffIndividualsTableFooter'

import { type IndividualsTable_individual } from '../../query/__generated__/IndividualsTable_individual.graphql'
import { type IndividualsTable_user } from '../../query/__generated__/IndividualsTable_user.graphql'

type IndividualsTableUserType = {
  multiSelectPayload: UseMultiSelectReturnTypes,
  queryVariables: { [string]: string | boolean },
  user: IndividualsTable_user,
}

type IndividualType = {
  individual: IndividualsTable_individual,
}

const customStyle = () => ({
  DataGrid: {
    maxHeight: 'initial',
  },
  headerVisible: {
    '& div': {
      ':last-child': {
        borderColor: 'transparent',
      },
    },
  },
  body: {
    overflow: 'hidden',
    '& .BasicCellRenderer': {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        padding: 0,
        '& > div': {
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& button': {
            margin: 0,
            padding: '1rem',
          },
        },
      },
      '&:nth-child(3)': {
        overflow: 'auto',
        display: 'flex',
      },
      '&:first-child': {
        paddingLeft: '60px',
        '& a': {
          lineHeight: '1rem',
        },
      },
      '& div': {
        padding: '0',
      },
    },
  },
  rowParentBorder: {
    position: 'relative',
  },
})

const renderRow = (
  data,
  index,
  props,
  children,
  footer,
  multiSelectPayload,
) => {
  const { componentId } = props
  const { id, initial_role_status } = data
  const rowId = componentId && id ? `${componentId}-${id}` : ''
  const newProps = omit(
    {
      ...props,
      className: classNames(props.className, rowId),
    },
    ['componentId', 'border', 'filterStatus'],
  )

  return (
    initial_role_status === props.filterStatus && (
      <div key={props.key} className={props.border}>
        <div {...newProps}>{children}</div>
        {footer && (
          <DataGridRowFooterRenderer
            content={footer(data)}
            multiSelectPayload={multiSelectPayload}
          />
        )}
      </div>
    )
  )
}

const compactData = (data) => {
  let _data
  if (data) {
    _data = data.map((e) => e?.node)
  } else {
    _data = []
  }
  return _data.filter(Boolean)
}

const StandardIndividualsTableComponent = (props: IndividualsTableUserType) => {
  const {
    queryVariables,
    user: {
      staff_individuals_page_actions: { standard },
    },
    user: {
      individuals: { edges },
    },
    multiSelectPayload,
  } = props

  const { t: translation } = useTranslation()
  const staffCliniciansColumnConfig = staffCliniciansColumn(translation)
  const { status: filterStatus } = queryVariables
  const tableFooter = ({ individual, individual: { role_status } }) => {
    const editAllowed = ['Active', 'Invited'].includes(role_status)
    const canEditEmr = editAllowed && standard.includes('medical_director')

    return (
      <StaffIndividualsTableFooter
        canEditEmr={canEditEmr}
        individual={individual}
      />
    )
  }

  return (
    <DataGrid
      extend={customStyle}
      renderRow={renderRow}
      data={compactData(edges)}
      componentId="StandardIndividualsTableBody"
      columns={['individual', 'role_status', 'clinicians', 'actions']}
      footer={tableFooter}
      mapRowProps={(rowData, _, rowProps) => ({
        ...rowProps,
        filterStatus,
      })}
      mapData={(individual) => {
        const { duty_of_care_clinicians, role_status } = individual
        return {
          id: individual.user.id,
          emrEditable: true,
          individual,
          initial_role_status: role_status,
          role_status: getRoleStatus(individual, translation),
          clinicians: {
            clinicians: duty_of_care_clinicians,
          },
          actions: {
            individual,
          },
        }
      }}
      columnConfig={{
        individual: {
          ...staffInvitedIndividualColumnConfig,
          label: translation('patient_name'),
          dataAttr: { name: 'data-component-id', value: 'name' },
          width: 'calc(calc(100% + 120px)/3)',
          sortable: false,
        },
        role_status: {
          label: translation('status'),
          format: (status: string) => <TextCell>{status}</TextCell>,
          dataAttr: { name: 'data-component-id', value: 'role_status' },
          sortable: false,
          width: 'calc(calc(100% - 60px)/3)',
        },
        clinicians: {
          ...staffCliniciansColumnConfig,
          label: capitalize(translation('clinician_other')),
          sortable: false,
          width: 'calc(calc(100% - 180px)/3)',
        },
        actions: {
          width: '40px',
          label: ' ',
          sortable: false,
          format: ({ individual }: IndividualType) =>
            standard.length > 0 && (
              <IndividualsTableStandardActions individual={individual} />
            ),
        },
      }}
      multiSelectPayload={multiSelectPayload}
    />
  )
}

export default StandardIndividualsTableComponent
