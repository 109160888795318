// @flow

import { formatDistanceWithOptions } from 'date-fns/fp'
import { enUS, fr } from 'date-fns/locale'

import getQueryParams from 'platform_web/utility/getQueryParams'

// TODO: Replace the language detection logic with i18next
// to dynamically retrieve the user's preferred language. For example:
// const getLanguage = () => i18next.language || window.localStorage.i18nextLng;
export const formatDistance = () => {
  const queryParams = getQueryParams()
  const { lng = 'en' } = queryParams
  if (lng === 'fr') {
    return formatDistanceWithOptions({ locale: fr })
  }
  return formatDistanceWithOptions({ locale: enUS })
}
